import { jsPDF } from "jspdf";
import { imgVertical } from "../dataImg";
import { footerQrBigLabelLandscape } from "../shared/footerBigLabel";
import { getCodeString } from "../../../components/serialHelpers/helpers";

async function printSerialsLabels(serials, order) {
  const doc = new jsPDF("l", "mm", [104, 159]);
  doc.setFont("helvetica", "normal");
  for (var i = 0; i < serials.length; i++) {
    const variantString = getCodeString(serials[i].remarks || "")?.replace(/\*/g, "");
    getSingleSerialsLabel(doc, serials[i], order, variantString);
    if (i < serials.length - 1) doc.addPage();
  }
  doc.save(`SerialsLabels.pdf`);
}

function printSerialLabel(serial, order, refValue) {
  const doc = new jsPDF("l", "mm", [104, 159]);
  doc.setFont("helvetica", "normal");
  getSingleSerialsLabel(doc, serial, order, refValue);
  doc.save(`serial_label_${serial.SerialID}.pdf`);
}

function getSingleSerialsLabel(doc, serial, order, refValue) {
  var colour = "Black";
  if (String(refValue).substring(9, 10) === "2") colour = "White";

  // if the refValu contains "PMJ" then weight is 20.4kg else 12.7kg
  const machineWeight = refValue.includes("PMJ") ? "20.4kg" : "12.7kg";

  doc.addImage(imgVertical, "png", 5, 5, 35, 50, "", "FAST");

  //pm machine info
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Serial number", 55, 12);
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(`${serial.SerialID}`, 55, 17);

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Produt reference", 55, 25);
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(refValue, 55, 30);

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Colour", 55, 37);
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(colour, 55, 42);

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Software", 55, 49);
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(`${serial.Software}`, 55, 54);

  //order info
  //order
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Sales order", 95, 12);
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(`${order.order_number}`, 95, 17);
  //ref
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Customer ref", 95, 25);
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(`${order.reference_customer}`, 95, 30);
  //customer
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Customer name", 95, 37);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(`${order.customer.company_name}`, 95, 42);
  //weight
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Estimated weight", 95, 49);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text(machineWeight, 95, 54);

  //footer
  doc.line(5, 67, 153, 67);
  footerQrBigLabelLandscape(doc);

  return doc;
}

export { printSerialsLabels, printSerialLabel };
