import { jsPDF } from "jspdf";
import { imgVertical, helpQR } from "../dataImg";

async function printFinishedProductLabel(product) {

  const machineWeight = (product.reference?.includes("PMG")) ? "12.7kg" : "20.4kg"
  const doc = new jsPDF("l", "mm", [104, 159]);

  doc.addImage(imgVertical, "png", 5, 3, 35, 50, '', 'FAST');

  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text("Serial number", 55, 10);
  doc.setFont("helvetica", "bold");
  doc.text(product.serialnumber, 55, 16);

  doc.setFont("helvetica", "normal");
  doc.text("Product reference", 55, 27);
  doc.setFont("helvetica", "bold");
  doc.text(product.reference, 55, 33);

  doc.setFont("helvetica", "normal");
  doc.text("Colour", 55, 44);
  doc.setFont("helvetica", "bold");
  doc.text(product.color, 55, 50);

  doc.setFont("helvetica", "normal");
  doc.text("Software", 110, 10);
  doc.setFont("helvetica", "bold");
  doc.text(product.software, 110, 16);

  doc.setFont("helvetica", "normal");
  doc.text("Recipe", 110, 27);
  doc.setFont("helvetica", "bold");
  doc.text(product.recipe, 110, 33);

  doc.setFont("helvetica", "normal");
  doc.text("Weight", 110, 44);
  doc.setFont("helvetica", "bold");
  doc.text(machineWeight, 110, 50);

  doc.line(5, 67, 153, 67);
  doc.addImage(helpQR, "png", 25, 72, 25, 25, '', 'FAST');
  doc.setFontSize(8);
  doc.text("help.perfectmoose.com", 22, 100);

  doc.addImage(product.barcode, "JPEG", 85, 70, '', '', '', 'FAST');

  doc.save(`serial_${product.serialnumber}.pdf`);
}

export { printFinishedProductLabel };
