import { useState, useEffect, useCallback, useRef } from "react";
import { Page, Card, DescriptionList, DataTable, Badge, Layout, Scrollable, ResourceItem, ResourceList, Link, Text, EmptyState, Banner, Icon, BlockStack, InlineStack } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { epochToLocal, formatDate, getRequest, putRequest } from "../../shared/components/functions";
import { S3ImageUploader } from "../../shared/components/s3_image_display/s3ImageUploader";
import { FeedbackHistory } from "../workstation/components/feedbackHistory";
import { Scanner } from "../../shared/components/barcodes/reader";
import { ChecksOverviewModal } from "../workstation/components/checksOverviewModal";
import { SerialsModal } from "../../shared/components/serialHelpers/serialsModal";
import { useToggle } from "../../shared/hooks/useToggle";
import { Toasts } from "../../shared/components/toasts/toasts";
import { EditIcon } from "@shopify/polaris-icons";
import { useLookupStore } from "../../utils/useStore";
import { serialsWithMelexisV3 } from "./melexisv3";
import { CardHeading } from "../../shared/components/CardHeading";
import { printFinishedProductLabel } from "../../shared/dymo/labels/finished_product_labels/pdfFinishedProductBox";
import JsBarcode from "jsbarcode";

/*
  Sample serial data:
  {
    "serialnumber": 20223096,
    "qualityCheckDate": "2022-02-22",
    "qualityCheckByUserID": 57,
    "remarks": "",
    "softwareTypeID": 9,
    "hardwareTypeID": 2,
    "recipeTypeID": 9,
    "productTypeID": 5,
    "hardwareBoilerTypeID": 4,
    "Status": "created",
    "ScannedPars" 
}
*/

export default function SerialPage() {
  const { id } = useParams();
  const { users, workstations, softwaretypes, recipetypes } = useLookupStore();
  const [serial, setSerial] = useState({});
  const [rfidEvents, setRfidEvents] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [checks, setChecks] = useState([]);
  const [modal, setModal] = useToggle(false);
  const [toast, setToast] = useState(null);
  const barcodeRef = useRef();
  const barcode = <img id="machineBarcode" ref={barcodeRef}></img>;


  const fetchSerial = useCallback(async () => {
    const serialData = await getRequest(`/api/serials/${id}`);
    if (serialData) {
      setSerial(serialData);
      const operatorInteractions = JSON.parse(serialData.OperatorInteractions);
      if (operatorInteractions) {
        const items = operatorInteractions.feedback.sort((a, b) => (a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0));
        setFeedback(items);
      }
      // console.log(typeof serialData.Checks);
      setChecks(JSON.parse(serialData.Checks || null));
      setLoaded(true);
    }

    const rfidEventsData = await getRequest(`/api/serials/rfid_events/${id}`);
    setRfidEvents(rfidEventsData);
  }, []);

  useEffect(() => {
    fetchSerial();
  }, []);

  const rows = rfidEvents.map((item) => {
    const typeTag = item.type === "Entry" ? <Badge tone="success">{item.type}</Badge> : item.type === "Exit" ? <Badge tone="warning">{item.type}</Badge> : <Badge tone="info">{item.type}</Badge>;
    return [item.id, item.Location, typeTag, epochToLocal(item.timestamp)];
  });

  const scannedParts = serial.ScannedParts ? JSON.parse(serial.ScannedParts || "[]") : [];

  // hardcoded hack asked by Erdem
  const machineHasMelexisV3 = serialsWithMelexisV3.includes(parseInt(id + "")) ? true : false;

  return (
    <Page title="Serial number" subtitle={`ID: ${id}`} fullWidth>
      {modal && (
        <SerialsModal
          modalActive={modal}
          closeModal={() => {
            setModal(false);
            fetchSerial();
          }}
          isEditSerial={true}
          isRFID={false}
          serialToEdit={serial}
          setSerialToast={setToast}
        />
      )}

      <Toasts toast={toast} setToast={setToast} toastContent="Serial number saved" />

      <Layout>
        {machineHasMelexisV3 && (
          <Layout.Section>
            <Banner tone="critical" title="Attention, this machine has a Melexis V3 temperature sensor!" />
          </Layout.Section>
        )}

        <Layout.Section>
        <div style={{ display: "none" }}>{barcode}</div>
          <BlockStack gap="400">
            <Card>
              <CardHeading
                title="Summary"
                actions={[
                  {
                    icon: EditIcon,
                    onAction: setModal,
                    variant: "secondary"
                  },
                  {
                    content: "Print Label",
                    onAction: async () => {


                      JsBarcode("#machineBarcode", serial.serialnumber.toString(), { width: 2, height: 90, fontSize: 10 });

                      const product = {
                        serialnumber: serial.serialnumber + "",
                        reference: serial.remarks.replace(/\*/g, ""),
                        color: serial.remarks.substring(11, 13) === "01" ? "Black" : "White",
                        software: softwaretypes.find((s) => s.SofwareTypeID === serial.softwareTypeID)?.Software,
                        recipe: recipetypes.find((s) => s.RecipeTypeID === serial.recipeTypeID)?.Recipe,
                        barcode: barcodeRef.current.src,
                      };

                      console.log(product);

                      
                      printFinishedProductLabel(product);
                    },
                    variant: "primary"
                  }
                ]}
              />
              <DescriptionList
                items={[
                  { term: "Serial number", description: serial.serialnumber },
                  { term: "Quality check date", description: serial.qualityCheckDate },
                  { term: "Quality check by user ID", description: serial.qualityCheckByUserID },
                  { term: "Remarks", description: serial.remarks },
                  { term: "Software type ID", description: serial.softwareTypeID },
                  { term: "Hardware type ID", description: serial.hardwareTypeID },
                  { term: "Recipe type ID", description: serial.recipeTypeID },
                  // { term: "Product type ID", description: serial.productTypeID },
                  { term: "Hardware boiler type ID", description: serial.hardwareBoilerTypeID },
                  { term: "Status", description: serial.Status },
                  { term: "RFID LOCATION", description: serial.RfidLocation },
                ]}
              />
            </Card>
            <Card>
              <CardHeading title="RFID history" />
              {rows.length > 0 ? (
                <Scrollable style={{ height: "750px" }}>
                  <DataTable
                    verticalAlign="middle"
                    increasedTableDensity
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={[<b>ID</b>, <b>Location</b>, <b>Type</b>, <b>Timestamp</b>]}
                    rows={rows}
                  />
                </Scrollable>
              ) : (
                <p>No RFID events found</p>
              )}
            </Card>
          </BlockStack>
        </Layout.Section>

        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Sales order" />
              {serial.sales_orders && serial.sales_orders.length > 0 ? (
                <ResourceList
                  items={serial.sales_orders}
                  renderItem={(item) => {
                    return (
                      <ResourceItem>
                        <BlockStack gap="200">
                          <InlineStack align="space-between">
                            <Link removeUnderline target="_blank" url={`https://admin.schuilenburg.be/sales/${item.OrderbevestigingID}`}>
                              #{item.Orderbevestigingnummer}
                            </Link>
                            <Text>{formatDate(item.Datum)}</Text>
                          </InlineStack>
                          <Link removeUnderline target="_blank" url={`https://admin.schuilenburg.be/companies/${item.KlantID}`}>
                            {item?.NAAM}
                          </Link>
                          {item.Notities && <div style={{ whiteSpace: "pre-wrap" }}>Note: {item.Notities}</div>}
                        </BlockStack>
                      </ResourceItem>
                    );
                  }}
                />
              ) : (
                <EmptyState heading="No sales assigned" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
              )}
            </Card>

            <Card>
              <CardHeading title="Checks" />
              {checks ? (
                <ChecksOverviewModal checks={checks} workstations={workstations} isWorkstation={false} />
              ) : (
                <EmptyState heading="No checks assigned" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
              )}
            </Card>

            {loaded && feedback.length > 0 && (
              <Card>
                <CardHeading title="Feedback history" />
                <FeedbackHistory feedback={feedback} setFeedback={setFeedback} loaded={loaded} workstations={workstations} users={users} />
              </Card>
            )}

            <S3ImageUploader
              S3ObjectKeys={serial.S3ObjectKeys || "[]"}
              onUploadImages={async (updatedS3ObjectKeys) => {
                const updatedSerial = { ...serial, S3ObjectKeys: JSON.stringify(updatedS3ObjectKeys) };
                setSerial(updatedSerial);
                await putRequest(`/api/serials/${id}`, updatedSerial);
              }}
              onRemoveImages={async (updatedS3ObjectKeys) => {
                const updatedSerial = { ...serial, S3ObjectKeys: JSON.stringify(updatedS3ObjectKeys) };
                setSerial(updatedSerial);
                await putRequest(`/api/serials/${id}`, updatedSerial);
              }}
              S3ObjectNamePrefix={`serial_${id}`}
            />

            <Card>
              <CardHeading title="Attached parts" />
              <Scanner
                onBarcodeRead={async (barcode) => {
                  // console.log(`Barcode read in parent: ${barcode}`);
                  const updatedScannedParts = [...scannedParts, barcode];
                  const updatedSerial = { ...serial, ScannedParts: JSON.stringify(updatedScannedParts) };
                  setSerial(updatedSerial);
                  await putRequest(`/api/serials/${id}`, updatedSerial);
                }}
              />

              {scannedParts?.length > 0 ? (
                scannedParts.map((part, idx) => {
                  return <Text key={idx}>{part}</Text>;
                })
              ) : (
                <p>No parts attached</p>
              )}
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
